import { POST } from "@/rest/client";

export async function changeEmail(
  userID: string,
  token: string
): Promise<boolean> {
  try {
    await POST(`/users/${userID}/change-email`, null, {
      ect: token,
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function requestEmailChange(
  email: string,
  password: string,
  otp: string | undefined
) {
  try {
    await POST(
      `/users/change-email/request`,
      {
        newEmail: email,
        password: password,
        otp: otp ? otp : "",
      },
      {}
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}
