interface ChangelogEntry {
    version: string;
    changes: string[];
}
  
interface RawChangelog {
[date: string]: ChangelogEntry[]; 
}

function formatChangelog(changelogRaw: RawChangelog): string {
    let output = "";
    for (const date of Object.keys(changelogRaw)) {
        output += `<b>${date}</b> - Update to ${changelogRaw[date][0].version}<br>\n`;
        output += "<ul style='margin-left: 20px'>\n";
        for (const change of changelogRaw[date][0].changes) {
            output += `<li style='list-style: disc;'>${change}</li>\n`;
        }
        output += "</ul><br>\n";
    }

    return output;
}

const changelog_raw_en: RawChangelog = {
    "2024-08-04": [
        {
            version: "v0.7.5",
            changes: [
                "Added full transaction history with year filter",
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-06-08": [
        {
            version: "v0.7.4",
            changes: [
                "Performance improvements and enhanced error tracking",
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-05-22": [
        {
            version: "v0.7.3",
            changes: [
                "Small visual overhaul of the the entire webpanel",
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-05-09": [
        {
            version: "v0.7.2",
            changes: [
                "Added support for power actions on virtual servers",
                "Added support to add other admins to your servers",
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-05-08": [
        {
            version: "v0.7.1",
            changes: [
                "Added support for WoL (Wake on LAN) for dedicated servers",
                "Added support for Nicknames for servers",
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-05-07": [
        {
            version: "v0.7.0",
            changes: [
                "Added Alpha testing of direct dedicated server rebooting",
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-05-05": [
        {
            version: "v0.6.3",
            changes: [
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-05-02": [
        {
            version: "v0.6.2.2",
            changes: [
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-04-19": [
        {
            version: "v0.6.2",
            changes: [
                "Reimplemented Stripe Payment workflow to make it more user-friendly",
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-04-18": [
        {
            version: "v0.6.1",
            changes: [
                "Minor visual changes",
            ]
        }
    ],
    "2024-04-15": [
        {
            version: "v0.6.0",
            changes: [
                "Added Affiliate system beta (contact us if you are interested ^^)",
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-04-07": [
        {
            version: "v0.5.8.5",
            changes: [
                "Added Klarna payment option",
                "Removed SOFORT payment option due to stripe retiring the service",
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-04-05": [
        {
            version: "v0.5.8.4",
            changes: [
                "Minor bug fixes and improvements",
            ]
        }
    ],
    "2024-03-26": [
        {
            version: "v0.5.8.3",
            changes: [
                "Added \"Recent Updates\" section to dashboard",
                "Other minor bug fixes and improvements",
            ]
        }
    ],
    "2024-03-25": [
        {
            version: "v0.5.8.2",
            changes: [
                "Moved store feature from alpha to beta",
                "Other minor bug fixes and improvements",
            ]
        }
    ],
    "2024-03-22": [
        {
            version: "v0.5.8",
            changes: [
                "Added different background options to settings",
                "Added RTT to server details and metrics",
                "Improved product sorting in store",
                "Other minor bug fixes and improvements",
            ]
        }
    ]
}

const changelog_raw_de: RawChangelog = {
    "2024-08-04": [
        {
            version: "v0.7.5",
            changes: [
                "Volle Transaktionshistorie mit Jahresfilter hinzugefügt",
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-06-08": [
        {
            version: "v0.7.4",
            changes: [
                "Leistungsverbesserungen und verbessertes Fehlertracking",
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-05-22": [
        {
            version: "v0.7.3",
            changes: [
                "Kleine visuelle Überarbeitung des gesamten Webpanels",
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-05-09": [
        {
            version: "v0.7.2",
            changes: [
                "Unterstützung für Power-Aktionen auf virtuellen Servern hinzugefügt",
                "Unterstützung für andere Admins auf deinen Servern hinzugefügt",
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-05-08": [
        {
            version: "v0.7.1",
            changes: [
                "Wake on LAN (WoL) Unterstützung für dedizierte Server hinzugefügt",
                "Unterstützung für Nicknames für Server hinzugefügt",
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-05-07": [
        {
            version: "v0.7.0",
            changes: [
                "Alpha-Test des direkten Neustarts von dedizierten Servern hinzugefügt",
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-05-05": [
        {
            version: "v0.6.3",
            changes: [
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-04-19": [
        {
            version: "v0.6.2",
            changes: [
                "Stripe-Zahlungsworkflow neu implementiert, um ihn benutzerfreundlicher zu gestalten",
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-04-18": [
        {
            version: "v0.6.1",
            changes: [
                "Kleine visuelle Änderungen",
            ]
        }
    ],
    "2024-04-15": [
        {
            version: "v0.6.0",
            changes: [
                "Affiliate-System BETA hinzugefügt (Kontaktiere uns, wenn du interessiert bist ^^)",
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-04-07": [
        {
            version: "v0.5.8.5",
            changes: [
                "Klarna-Zahlungsoption hinzugefügt",
                "SOFORT-Zahlungsoption entfernt, da Stripe den Dienst einstellt",
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-04-05": [
        {
            version: "v0.5.8.4",
            changes: [
                "Kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-03-26": [
        {
            version: "v0.5.8.3",
            changes: [
                "\"Letzte Updates\" Abschnitt zum Dashboard hinzugefügt",
                "Weitere kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-03-25": [
        {
            version: "v0.5.8.2",
            changes: [
                "Store-Funktion nun in Beta-Phase",
                "Weitere kleine Bugfixes und Verbesserungen",
            ]
        }
    ],
    "2024-03-22": [
        {
            version: "v0.5.8",
            changes: [
                "Verschiedene Hintergrundoptionen zu den Einstellungen hinzugefügt",
                "RTT zu Serverdetails und Metrics hinzugefügt",
                "Produktsortierung im Store verbessert",
                "Weitere kleine Bugfixes und Verbesserungen",
            ]
        }
    ]
}

export function getChangelog(language: string): string {
    let output = "";
    if (language === "de") {
        output = formatChangelog(changelog_raw_de);
        output += "Änderungen in älteren Versionen nicht verfügbar.";
        return output;
    }
    output = formatChangelog(changelog_raw_en);
    output += "Changes in older versions not available.";
    return output;
}


