import type { FullUser } from "@/@types/user";
import { GET, PATCH } from "@/rest/client";

export async function getMyUser(): Promise<FullUser | undefined> {
  try {
    return await GET<FullUser>("/users/@me", {
      full: true,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

// PATCH Update UserInfo -> sends a patch request to /users/@me and gets a 200 OK -> "User updated successfully" response
async function updateMyUser(user: Partial<FullUser>): Promise<boolean> {
  try {
    await PATCH("/users/@me", user);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// use updateMyUser to update the userData in the backend and when successful query getMyUser to get the updated user data
export async function updateMyUserAndQuery(
  user: Partial<FullUser>
): Promise<FullUser | undefined> {
  const success = await updateMyUser(user);
  if (success) {
    return await getMyUser();
  }
  return undefined;
}

// update user locale in the backend
export async function updateMyLocale(locale: string): Promise<boolean> {
  try {
    await PATCH("/users/locale", {}, { locale: locale });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getMyCategories(): Promise<string[]> {
  try {
    return await GET<string[]>("/users/categories");
  } catch (e) {
    console.error(e);
    return [];
  }
}
