import { defineStore } from "pinia";
import type { FullUser } from "@/@types/user";
import type { Payment } from "@/@types/payment";
import type { Transaction } from "@/@types/transaction";
import type { Contract } from "@/@types/contract";

import type { Server } from "@/@types/server";

import type { Gameserver } from "@/@types/gameserver";

import type { StoreProduct } from "@/@types/product";

export const useGeneralStore = defineStore("general", {
  state: () => ({
    user: undefined as FullUser | undefined,
    pendingPayments: undefined as Payment[] | undefined,
    lastTransactions: undefined as Transaction[] | undefined,
    contracts: undefined as Contract[] | undefined,
    servers: undefined as Server[] | undefined,
    adminservers: undefined as Server[] | undefined,
    gameservers: undefined as Gameserver[] | undefined,
    admingameservers: undefined as Gameserver[] | undefined,
    storeproducts: undefined as StoreProduct[] | undefined,
    storecart: undefined as StoreProduct[] | undefined,
    categories: [] as string[],
  }),
  getters: {
    isLoggedIn(state) {
      return !!state.user;
    },

    isAdmin(state) {
      return state.user?.isAdmin ?? false;
    },

    isAffiliate(state) {
      return state.user?.isAffiliate ?? false;
    },

    hasPendingPayments(state) {
      return !!state.pendingPayments && state.pendingPayments.length > 0;
    },
    hasLastTransactions(state) {
      return !!state.lastTransactions && state.lastTransactions.length > 0;
    },
    hasPendingBankPayment(state) {
      return (
        !!state.pendingPayments &&
        state.pendingPayments.some((p) => p.type === "bank_transfer")
      );
    },
    hasPendingStripePayment(state) {
      return (
        !!state.pendingPayments &&
        state.pendingPayments.some((p) => p.type === "stripe")
      );
    },
    getFirstPendingStripePayment(state) {
      return state.pendingPayments?.find((p) => p.type === "stripe");
    },
    getGameserverById: (state) => (id: string, admin: boolean = false) => {
      if (admin) {
        return state.admingameservers?.find((s) => s.id === id);
      }
      return state.gameservers?.find((s) => s.id === id);
    },
    isInCart: (state) => (id: string) => {
      return (
        !!state.storecart && state.storecart.some((s) => s.product_id === id)
      );
    },
    hasCategory: (state) => (category: string) => {
      return state.categories.includes(category);
    },
  },
  actions: {
    setUser(user: FullUser | undefined) {
      this.user = user;
    },

    setPendingPayments(payments: Payment[] | undefined) {
      this.pendingPayments = payments;
    },

    setLastTransactions(transactions: Transaction[] | undefined) {
      this.lastTransactions = transactions;
    },

    setContracts(contracts: Contract[] | undefined) {
      this.contracts = contracts;
    },

    setServers(servers: Server[] | undefined) {
      this.servers = servers;
    },

    setServer(server: Server | undefined) {
      if (!server) return;
      if (!this.servers) {
        this.servers = [server];
        return;
      }
      const index = this.servers.findIndex((s) => s.id === server.id);
      if (index === -1) {
        this.servers.push(server);
        return;
      }
      this.servers[index] = server;
    },

    setAdminServer(server: Server | undefined) {
      if (!server) return;
      if (!this.adminservers) {
        this.adminservers = [server];
        return;
      }
      const index = this.adminservers.findIndex((s) => s.id === server.id);
      if (index === -1) {
        this.adminservers.push(server);
        return;
      }
      this.adminservers[index] = server;
    },

    setAdminServers(servers: Server[] | undefined) {
      this.adminservers = servers;
    },

    setGameservers(gameservers: Gameserver[] | undefined) {
      this.gameservers = gameservers;
    },

    setGameserver(gameserver: Gameserver | undefined) {
      if (!gameserver) return;
      if (!this.gameservers) {
        this.gameservers = [gameserver];
        return;
      }
      const index = this.gameservers.findIndex((s) => s.id === gameserver.id);
      if (index === -1) {
        this.gameservers.push(gameserver);
        return;
      }
      this.gameservers[index] = gameserver;
    },

    setAdminGameservers(gameservers: Gameserver[] | undefined) {
      this.admingameservers = gameservers;
    },

    setStoreProducts(storeproducts: StoreProduct[] | undefined) {
      this.storeproducts = storeproducts;
    },

    addToStoreCart(storeproduct: StoreProduct | undefined) {
      if (!storeproduct) return;
      if (!this.storecart) {
        this.storecart = [storeproduct];
        return;
      }
      const index = this.storecart.findIndex((s) => s.id === storeproduct.id);
      if (index === -1) {
        this.storecart.push(storeproduct);
        return;
      }
      this.storecart[index] = storeproduct;
    },

    removeFromStoreCart(storeproduct: StoreProduct | undefined) {
      if (!storeproduct) return;
      if (!this.storecart) {
        this.storecart = [storeproduct];
        return;
      }
      const index = this.storecart.findIndex((s) => s.id === storeproduct.id);
      if (index === -1) {
        return;
      }
      this.storecart.splice(index, 1);
    },

    clearStoreCart() {
      this.storecart = undefined;
    },

    setCategories(categories: string[]) {
      this.categories = categories;
    },
  },
});
