import { createRouter, createWebHistory } from "vue-router";
import { logout } from "@/rest/api/auth";
import { destroySession, isSessionExisting, setSession } from "@/rest/jwt";
import { useGeneralStore } from "@/stores/general";
import { getMyUser, getMyCategories } from "@/rest/api/users";
import { activateUser } from "@/rest/api/users.activation";
import { changeEmail } from "@/rest/api/users.emailchange";
import { getContracts } from "@/rest/api/contract";
import { getServer, getServers } from "@/rest/api/server";
import { getServersAdmin, getGameserversAdmin } from "@/rest/api/admin";
import { getGameserver, getGameservers } from "@/rest/api/gameserver";
import { getAllStoreProducts } from "@/rest/api/products";
import type { LoginToken } from "@/rest/dto/auth";
import { storeToRefs } from "pinia";

import {
  verifyStripeCheckoutSession,
  getPendingPayments,
  getTransactions,
  getPublicDonationLink,
} from "@/rest/api/payments";
import { use } from "chai";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/RegisterView.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView.vue"),
    },
    {
      path: "/login/finish-discord",
      name: "login-finish-discord",
      component: () => null,
      beforeEnter: (to, from, next) => {
        if (
          to.query.success &&
          to.query.success === "true" &&
          to.query.tokend
        ) {
          const base64 = to.query.tokend as string;
          const tokenJson = atob(base64);
          const pair = JSON.parse(tokenJson) as LoginToken;
          setSession(pair.token, pair.expiresIn);

          if (to.query.redirect) {
            next(atob(to.query.redirect as string));
          } else {
            next({ name: "panel-dashboard" });
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: () => null,
      beforeEnter: async (_, __, next) => {
        await _logout();

        next({ name: "login" });
      },
    },
    {
      path: "/activate/:userID",
      name: "activate",
      component: () => null,
      beforeEnter: async (to, _, next) => {
        const userID = to.params.userID as string;
        const token = to.query.activation as string;
        if (
          userID &&
          token &&
          userID.trim().length > 0 &&
          token.trim().length > 0
        ) {
          const success = await activateUser(userID, token);
          if (success) {
            next({ name: "login" });
          } else {
            next({ name: "home" });
          }
        } else {
          next({ name: "home" });
        }
      },
    },
    {
      path: "/changeMail/:userID",
      name: "changeMail",
      component: () => null,
      beforeEnter: async (to, _, next) => {
        const userID = to.params.userID as string;
        const token = to.query.ect as string;
        if (
          userID &&
          token &&
          userID.trim().length > 0 &&
          token.trim().length > 0
        ) {
          const success = await changeEmail(userID, token);
          if (success) {
            useGeneralStore().setUser(await getMyUser());
            next({ name: "panel-dashboard" });
          } else {
            next({ name: "home" });
          }
        } else {
          next({ name: "home" });
        }
      },
    },
    {
      path: "/reset-password/:userID",
      name: "reset-password",
      component: () => import("@/views/ResetPasswordView.vue"),
      beforeEnter: async (to, _, next) => {
        const userID = to.params.userID as string;
        const token = to.query.reset as string;
        const totp = to.query.totp as string;
        if (
          userID &&
          token &&
          totp &&
          userID.trim().length > 0 &&
          token.trim().length > 0 &&
          (totp === "true" || totp === "false")
        ) {
          next();
        } else {
          next({ name: "home" });
        }
      },
    },
    {
      path: "/store",
      name: "store",
      component: () => import("@/views/StoreView.vue"),
    },
    {
      path: "/panel",
      name: "panel",
      component: () => import("@/views/ControlPanelView.vue"),
      children: [
        {
          path: "dashboard",
          name: "panel-dashboard",
          component: () => import("@/views/panel/DashboardView.vue"),
        },
        {
          path: "store",
          name: "panel-store",
          component: () => import("@/views/store/Store.vue"),
          beforeEnter: async (to, _, next) => {
            const storeproducts = await getAllStoreProducts(100, true);
            if (!storeproducts) {
              next({ name: "home" });
              return;
            }
            // sort storeproducts by category and sorting
            storeproducts.sort((a, b) => {
              if (a.category === b.category) {
                return a.sorting - b.sorting;
              }
              return a.category.localeCompare(b.category);
            });

            useGeneralStore().setStoreProducts(storeproducts);
            next();
          },
        },
        {
          path: "store/cart",
          name: "panel-store-cart",
          component: () => import("@/views/store/Cart.vue"),
        },
        {
          path: "payments",
          name: "panel-payments",
          component: () => import("@/views/panel/PaymentsView.vue"),
          beforeEnter: async (to, _, next) => {
            const pendingPayments = await getPendingPayments();
            useGeneralStore().setPendingPayments(pendingPayments);

            const lastTransactions = await getTransactions();
            useGeneralStore().setLastTransactions(lastTransactions);
            next();
          },
        },
        {
          path: "transactions",
          name: "panel-transactions",
          component: () =>
            import("@/components/panel/payments/TransactionHistory.vue"),
        },
        {
          path: "servers",
          name: "panel-servers",
          component: () => import("@/views/panel/ServerListView.vue"),
          beforeEnter: async (to, _, next) => {
            const servers = await getServers(false, true, true, true);
            useGeneralStore().setServers(servers);
            next();
          },
        },
        {
          path: "gameservers",
          name: "panel-gameservers",
          component: () => import("@/views/panel/GameserverListView.vue"),
          beforeEnter: async (to, _, next) => {
            const gameservers = await getGameservers(false, true);
            useGeneralStore().setGameservers(gameservers);
            next();
          },
        },
        {
          path: "contracts",
          name: "panel-contracts",
          component: () => import("@/views/panel/ContractsView.vue"),
          beforeEnter: async (to, _, next) => {
            useGeneralStore().setContracts(await getContracts(true));
            next();
          },
        },
        {
          path: "settings",
          name: "panel-settings",
          component: () => import("@/views/panel/SettingsView.vue"),
        },
        {
          path: "affiliate",
          name: "panel-affiliate",
          component: () => import("@/views/panel/AffiliateView.vue"),
        },
        {
          path: "/pay/stripe",
          name: "panel-pay-stripe",
          component: () => import("@/components/panel/pay/StripeEmbed.vue"),
        },
      ],
    },
    {
      path: "/admin",
      name: "admin",
      component: () => import("@/views/AdminPanelView.vue"),
      children: [
        {
          path: "dashboard",
          name: "admin-dashboard",
          component: () => import("@/views/admin/DashboardView.vue"),
        },
        {
          path: "users",
          name: "admin-users",
          component: () => import("@/views/admin/UsersView.vue"),
        },
        {
          path: "banktransfers",
          name: "admin-banktransfers",
          component: () => import("@/views/admin/PendingBankTransferView.vue"),
        },
        {
          path: "products",
          name: "admin-products",
          component: () => import("@/views/admin/ProductsView.vue"),
        },
        {
          path: "servers",
          name: "admin-servers",
          component: () => import("@/views/panel/ServerListView.vue"),
          beforeEnter: async (to, _, next) => {
            const servers = await getServersAdmin(true, true, true, true);
            useGeneralStore().setAdminServers(servers);
            next();
          },
        },
        {
          path: "gameservers",
          name: "admin-gameservers",
          component: () => import("@/views/panel/GameserverListView.vue"),
          beforeEnter: async (to, _, next) => {
            const gameservers = await getGameserversAdmin(true);
            useGeneralStore().setAdminGameservers(gameservers);
            next();
          },
        },
        {
          path: "affiliate",
          name: "admin-affiliate",
          component: () => import("@/views/panel/AffiliateView.vue"),
        },
        {
          path: "settings",
          name: "admin-settings",
          component: () => import("@/views/panel/SettingsView.vue"),
        },
      ],
    },
    {
      path: "/payments/stripe/verify",
      name: "payments-stripe-verify",
      component: () => null,
      beforeEnter: async (to, _, next) => {
        const token = to.query.token as string;
        if (token && token.trim().length > 0) {
          const balance = await verifyStripeCheckoutSession(token);

          if (balance) {
            const store = useGeneralStore();
            const user = store.user;
            if (user) {
              user.balance = balance;
              store.setUser(user);
            }
            next({ name: "panel-payments" });
          } else {
            console.error("Failed to verify payment");
            next({ name: "panel-payments" });
          }
        } else {
          next({ name: "panel-payments" });
        }
      },
    },
    {
      path: "/payments/stripe/donation/public/:reference",
      name: "payments-stripe-donation-public",
      component: () => import("@/views/public/StripeDonation.vue"),
    },
  ],
});

const publicPages = [
  "home",
  "login",
  "login-finish-discord",
  "register",
  "activate",
  "reset-password",
  "payments-stripe-donation-public",
  "store",
];

const adminPages = [
  "admin",
  "admin-dashboard",
  "admin-users",
  "admin-servers",
  "admin-gameservers",
  "admin-settings",
];

router.beforeEach(async (to, from, next) => {
  try {
    const store = useGeneralStore();
    if (!store.user && isSessionExisting()) {
      const user = await getMyUser();

      store.setUser(user);

      if (!user) {
        await _logout();

        next({ name: "login", query: { redirect: btoa(to.fullPath) } });
        return;
      }
    }
    if (store.isLoggedIn && store.categories.length === 0) {
      const categories = await getMyCategories();
      store.categories = categories;
    }

    // check if logged in
    if (!store.isLoggedIn) {
      //check if public page
      if (publicPages.includes(to.name as string)) {
        next();
      } else {
        next({ name: "login", query: { redirect: btoa(to.fullPath) } });
      }
    }
    // check if admin page
    if (!store.isAdmin && adminPages.includes(to.name as string)) {
      next({ name: "panel-dashboard" });
    }
  } catch (e) {
    console.error(e);
  }

  next();
});

async function _logout() {
  await logout();

  destroySession();
  const store = useGeneralStore();
  store.setUser(undefined);
  store.setPendingPayments([]);
  store.setLastTransactions([]);
}

export default router;
