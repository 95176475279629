import type { FullUser } from "@/@types/user";
import { GET, PATCH, POST, PUT } from "@/rest/client";
import type { Contract } from "@/@types/contract";
import type { Transaction } from "@/@types/transaction";
import type { Server } from "@/@types/server";
import type { Gameserver } from "@/@types/gameserver";
import type { Payment } from "@/@types/payment";

export async function getAllUsers(): Promise<FullUser[] | undefined> {
  try {
    return await GET<FullUser[]>("/users/admin/", {
      full: true,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getUser(userid: string): Promise<FullUser | undefined> {
  try {
    return await GET<FullUser>(`/users/${userid}`, {
      full: true,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getContracts(
  userid: string,
  includeFullProduct: boolean = false
): Promise<Contract[] | undefined> {
  try {
    return await GET<Contract[]>(`/contract/admin/${userid}`, {
      includeCancellation: true,
      includeFullProduct: includeFullProduct,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getTransactions(
  userid: string
): Promise<Transaction[] | undefined> {
  try {
    return await GET<Transaction[]>(`/payments/admin/transactions/${userid}`, {
      amount: 1000,
      timestamp: true,
      full: true,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function renewUserContracts(userid: string): Promise<boolean> {
  try {
    await POST(`/payments/admin/createPayment/${userid}`, {}, {});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getServersAdmin(
  full: boolean = false,
  includeHardware: boolean = false,
  includeDate: boolean = false,
  includeOwner: boolean = false
): Promise<Server[] | undefined> {
  try {
    return await GET<Server[]>(`/server/admin/`, {
      full: full,
      includeHardware: includeHardware,
      includeDate: includeDate,
      includeOwner: includeOwner,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getGameserversAdmin(
  full: boolean = false,
  includeDate: boolean = false
): Promise<Gameserver[] | undefined> {
  try {
    return await GET<Gameserver[]>(`/gameserver/admin/`, {
      full: full,
      includeDate: includeDate,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getPendingContractsAdmin(
  includeFullProduct: boolean = false
): Promise<Contract[] | undefined> {
  try {
    return await GET<Contract[]>(`/contract/admin/pending`, {
      includeCancellation: true,
      includeFullProduct: includeFullProduct,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getPendingBankTransferPaymentsAdmin(): Promise<Payment[] | undefined> {
  try {
    return await GET<Payment[]>(`/payments/admin/banktransfer`);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function confirmPendingBankTransferPaymentAdmin(
  paymentId: string
): Promise<boolean> {
  try {
    await PUT(`/payments/admin/banktransfer`, { id: paymentId }, {});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}