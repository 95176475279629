<script setup lang="ts">

</script>

<template>
    <div class="web--centered-layout">
        <slot />
    </div>
</template>

<style scoped lang="scss">
.web--centered-layout {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>