import type {
  LoginBody,
  LoginErrorUnion,
  LoginToken,
  LoginTotpBody,
  RegisterBody,
} from "@/rest/dto/auth";
import { LoginErrorType, RegisterError } from "@/rest/dto/auth";
import { GET, POST, RestError } from "@/rest/client";
import { parseEnum } from "@/utils/enum";

export async function register(
  body: RegisterBody
): Promise<RegisterError | undefined> {
  try {
    await POST("/auth/register", body);
    return undefined;
  } catch (e) {
    if (e instanceof RestError) {
      return parseEnum(RegisterError, e.data.error, RegisterError.Unknown);
    } else {
      return RegisterError.Unknown;
    }
  }
}

export async function login(
  body: LoginBody
): Promise<LoginErrorUnion | LoginToken> {
  try {
    return await POST<LoginToken>("/auth/login", body);
  } catch (e) {
    if (e instanceof RestError) {
      const type = parseEnum(
        LoginErrorType,
        e.data.error,
        LoginErrorType.Unknown
      );
      switch (type) {
        case LoginErrorType.NotActivated:
        case LoginErrorType.OtpMissing:
          return {
            type,
            state: e.data.state,
          } as LoginErrorUnion;
        default:
          return {
            type,
          };
      }
    } else {
      return {
        type: LoginErrorType.Unknown,
      };
    }
  }
}

export async function loginTotp(
  body: LoginTotpBody
): Promise<LoginErrorUnion | LoginToken> {
  try {
    return await POST<LoginToken>("/auth/login/totp", body);
  } catch (e) {
    if (e instanceof RestError) {
      const type = parseEnum(
        LoginErrorType,
        e.data.error,
        LoginErrorType.Unknown
      );
      switch (type) {
        case LoginErrorType.NotActivated:
        case LoginErrorType.OtpWrong:
          return {
            type,
            state: e.data.state,
          } as LoginErrorUnion;
        default:
          return {
            type,
          };
      }
    } else {
      return {
        type: LoginErrorType.Unknown,
      };
    }
  }
}

export async function logout(): Promise<void> {
  try {
    await POST("/auth/logout");
  } catch (e) {
    console.error(e);
  }
}

export async function loginWithDiscord(
  redirect?: string
): Promise<string | undefined> {
  try {
    let redirectTo = window.location.origin;
    if (!redirectTo.endsWith("/")) {
      redirectTo += "/";
    }

    redirectTo += "login/finish-discord";

    if (redirect) {
      redirectTo += `?redirect=${redirect}`;
    }

    return (
      await GET<{ url: string }>("/auth/login/discord", {
        redirect_to: redirectTo,
      })
    ).url;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function beginFido2Login(): Promise<
  { state: string; options: any } | undefined
> {
  try {
    return await POST<{ state: string; options: any }>(
      "/auth/login/fido2/begin"
    );
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function endFido2Login(
  state: string,
  body: any
): Promise<LoginToken | undefined> {
  try {
    return await POST<LoginToken>("/auth/login/fido2/end", body, { state });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
