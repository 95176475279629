import type { Server, ServerAdmin, ServerMetrics, ServerProviderPowerAction } from "@/@types/server";
import { GET, POST, PATCH, DELETE } from "@/rest/client";

export async function getServer(
  serverId: string,
  full: boolean = false,
  includeHardware: boolean = false,
  includeDate: boolean = false,
  includeOwner: boolean = false,
  includeFullAdmins: boolean = false,
  includeModules: boolean = false
): Promise<Server | undefined> {
  try {
    return await GET<Server>(`/server/${serverId}`, {
      full: full,
      includeHardware: includeHardware,
      includeDate: includeDate,
      includeOwner: includeOwner,
      includeFullAdmins: includeFullAdmins,
      include_modules: includeModules,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getServers(
  full: boolean = false,
  includeHardware: boolean = false,
  includeDate: boolean = false,
  includeOwner: boolean = false,
  includeModules: boolean = false
): Promise<Server[] | undefined> {
  try {
    return await GET<Server[]>(`/server/`, {
      full: full,
      includeHardware: includeHardware,
      includeDate: includeDate,
      includeOwner: includeOwner,
      include_modules: includeModules,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getOnlineStatus(serverId: string): Promise<boolean> {
  try {
    await GET(`/server/${serverId}/ping`, {});
    return true;
  } catch (e) {
    return false;
  }
}

export async function getOnlineStatusWithPing(serverId: string): Promise<number> {
  try {
    await GET(`/server/${serverId}/ping`, {});
    return (await GET<{ rtt: number }>(`/server/${serverId}/ping`, {})).rtt;
  } catch (e) {
    return -1;
  }
}

export async function getServerMetrics(
  serverId: string
): Promise<ServerMetrics | undefined> {
  try {
    return await GET<ServerMetrics>(`/server/${serverId}/metrics`, {});
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getServerProviderPowerAction(
  serverId: string
): Promise<ServerProviderPowerAction | undefined> {
  try {
    return await GET(`/server/provider/${serverId}/poweractions`, {});
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function executeServerProviderPowerAction(
  serverId: string,
  type: string
): Promise<number> { // 0 = success, 1 = error, 2 = too many requests
  try {
    await POST(`/server/provider/${serverId}/poweractions/${type}`, {});
    return 0;
  } catch (e: unknown) {
    // Check if 'e' is an object containing 'response' with a 'status'
    if (typeof e === "object" && e !== null && "response" in e) {
      const err = e as { response: { status: number } };
      if (err.response.status === 429) {
        return 2;
      }
    }
    console.error(e);
    return 1;
  }
}

export async function setNickname(
  serverId: string,
  nickname: string
): Promise<boolean> {
  try {
    await PATCH<boolean>(
      `/server/${serverId}/nickname`,
      {
        nickname: nickname,
      },
      {}
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function addAdminToServerWithUserID(
  serverId: string,
  user_id: string
): Promise<ServerAdmin | undefined> {
  try {
    return await POST(`/server/${serverId}/admins`, { user_id: user_id });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function addAdminToServerWithEmail(
  serverId: string,
  email: string
): Promise<ServerAdmin | undefined> {
  try {
    return POST(`/server/${serverId}/admins`, { email: email });
  } catch (e) {
    console.error(e);
    return undefined
  }
}

export async function removeAdminFromServer(
  serverId: string,
  user_id: string
): Promise<boolean> {
  try {
    await DELETE(`/server/${serverId}/admins/${user_id}`, {});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function addWhitelistEntry(
  serverId: string,
  ip: string
): Promise<boolean> {
  try {
    await POST(`/server/${serverId}/whitelist`, { ip: ip });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function removeWhitelistEntry(
  serverId: string,
  ip: string
): Promise<boolean> {
  try {
    await POST(`/server/${serverId}/whitelist/delete`, { ip: ip});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}