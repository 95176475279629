import axios from "axios";
import { getSession, setSession, destroySession } from "./jwt";
import type { ErrorResponse } from "@/rest/dto";

/**
 * A time in milliseconds which will be subtracted from the session's expiration time to determine if the session is expired.
 * It is used to prevent the session from expiring while the user is still using the application, so this offset should be.
 */
const EXPIRE_THRESHOLD = 60_000;

const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  withCredentials: true,
});
const refreshClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  withCredentials: true,
});

client.interceptors.request.use(async (config) => {
  const session = getSession();
  if (session) {
    if (session.expires - EXPIRE_THRESHOLD < Date.now()) {
      try {
        const data = (
          await refreshClient.post<{ token: string; expiresIn: number }>(
            "/auth/refresh"
          )
        ).data;
        setSession(data.token, data.expiresIn * 1000);
        session.token = data.token;
      } catch (error: any) {
        destroySession();
        window.location.href = "/login";
        return config;
      }
    }

    config.headers.Authorization = `Bearer ${session.token}`;
  }

  return config;
});

export async function GET<T>(
  url: string,
  params?: { [key: string]: any }
): Promise<T> {
  try {
    const response = await client.get<T>(url, {
      params,
      headers: {
        "Content-Type": "application/json", // Adding Content-Type header
        ...client.defaults.headers.common, // Spread operator to merge any existing headers
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(
        error.response.statusText,
        error.response.status,
        error.response.data
      );
    }

    throw error;
  }
}

export async function POST<T>(
  url: string,
  data?: any,
  params?: { [key: string]: any }
): Promise<T> {
  try {
    const response = await client.post<T>(url, data, {
      params,
      headers: {
        "Content-Type": "application/json", // Adding Content-Type header
        ...client.defaults.headers.common, // Spread operator to merge any existing headers
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(
        error.response.statusText,
        error.response.status,
        error.response.data
      );
    }

    throw error;
  }
}

export async function PUT<T>(
  url: string,
  data?: any,
  params?: { [key: string]: any }
): Promise<T> {
  try {
    const response = await client.put<T>(url, data, {
      params,
      headers: {
        "Content-Type": "application/json", // Adding Content-Type header
        ...client.defaults.headers.common, // Spread operator to merge any existing headers
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(
        error.response.statusText,
        error.response.status,
        error.response.data
      );
    }

    throw error;
  }
}

export async function DELETE<T>(
  url: string,
  params?: { [key: string]: any }
): Promise<T> {
  try {
    const response = await client.delete<T>(url, {
      params,
      headers: {
        "Content-Type": "application/json", // Adding Content-Type header
        ...client.defaults.headers.common, // Spread operator to merge any existing headers
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(
        error.response.statusText,
        error.response.status,
        error.response.data
      );
    }

    throw error;
  }
}

export async function PATCH<T>(
  url: string,
  data?: any,
  params?: { [key: string]: any }
): Promise<T> {
  try {
    const response = await client.patch<T>(url, data, {
      params,
      headers: {
        "Content-Type": "application/json", // Adding Content-Type header
        ...client.defaults.headers.common, // Spread operator to merge any existing headers
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(
        error.response.statusText,
        error.response.status,
        error.response.data
      );
    }

    throw error;
  }
}

export class RestError<T = ErrorResponse> extends Error {
  constructor(message: string, public status: number, public data: T) {
    super(message);
  }
}
