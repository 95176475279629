export interface RegisterBody {
  email: string;
  password: string;
  firstName: string;
  lastName: string;

  invitationCode?: string;
}

export enum RegisterError {
  Unknown = "Unknown error",
  RegistrationDisabled = "Registration is disabled",
  RegistrationOnlyMTN = "Registration is only allowed for mtn team members",
  EmailTaken = "Email is already taken",
  EmailInvalid = "Invalid email",
}

export function registerErrorToMessage(error: RegisterError): string {
  switch (error) {
    case RegisterError.RegistrationDisabled:
      return "Registration is disabled";
    case RegisterError.EmailTaken:
      return "Email is already taken";
    case RegisterError.EmailInvalid:
      return "Invalid email";
    case RegisterError.RegistrationOnlyMTN:
      return "Registration is currently only allowed for MTN team members";
    default:
      return "Unknown error";
  }
}

export interface LoginBody {
  email: string;
  password: string;
}

export interface LoginTotpBody {
  state: string;
  code: string;
}

export interface LoginToken {
  token: string;
  expiresIn: number;
}

export enum LoginErrorType {
  Unknown = "Unknown error",
  NotActivated = "User is not activated",
  OtpMissing = "OTP is missing",
  OtpWrong = "OTP is wrong",
  UserNotFound = "User not found",
  InvalidPassword = "Invalid password",
  UserBanned = "User is banned",
  InvalidState = "Invalid state",
}

export function loginErrorToMessage(error: LoginErrorType): string {
  switch (error) {
    case LoginErrorType.NotActivated:
      return "User not activated";
    case LoginErrorType.OtpWrong:
      return "OTP is wrong";
    case LoginErrorType.UserNotFound:
      return "User not found";
    case LoginErrorType.InvalidPassword:
      return "Invalid password";
    case LoginErrorType.UserBanned:
      return "User is banned";
    default:
      return "Unknown error";
  }
}

export interface LoginError {
  type: LoginErrorType;
}

export interface LoginErrorWithState extends LoginError {
  type:
    | LoginErrorType.NotActivated
    | LoginErrorType.OtpMissing
    | LoginErrorType.OtpWrong;
  state: string;
}

export type LoginErrorUnion = LoginError | LoginErrorWithState;
