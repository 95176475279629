import type { Contract } from "@/@types/contract";
import { POST, GET } from "@/rest/client";

export async function getContract(
  contractId: string,
  includeFullProduct: boolean = false
): Promise<Contract | undefined> {
  try {
    return await GET<Contract>(`/contract/${contractId}`, {
      includeCancellation: true,
      includeFullProduct: includeFullProduct,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getContracts(
  includeFullProduct: boolean = false
): Promise<Contract[] | undefined> {
  try {
    return await GET<Contract[]>(`/contract/`, {
      includeCancellation: true,
      includeFullProduct: includeFullProduct,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getContractCancellationDates(
  contractId: string,
  amount: number
): Promise<string[] | undefined> {
  try {
    return await GET<string[]>(
      `/contract/${contractId}/cancel/dates?amount=${amount}`
    );
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function cancelContractAPI(id: string, date: string) {
  try {
    await POST(
      `/contract/${id}/cancel`,
      {
        date: date,
      },
      {}
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function withdrawCancelContractAPI(id: string) {
  try {
    await POST(`/contract/${id}/cancel/withdraw`, {}, {});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}


export async function startAutomation(id: string) {
  try {
    await POST(`/contract/${id}/automation`, {}, {});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}