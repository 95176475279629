import type { Product, StoreProduct } from "@/@types/product";
import { PATCH, GET, POST } from "@/rest/client";

export async function getAllProducts(
  limit: number = 100
): Promise<Product[] | undefined> {
  try {
    return await GET<Product[]>(`/product/admin/`, {
      limit: limit,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getProduct(
  productId: string
): Promise<Product | undefined> {
  try {
    return await GET<Product>(`/product/${productId}`, {});
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function updateProduct(
  productid: string,
  productname: string,
  productdescription: string,
  productprice: number
): Promise<Product | undefined> {
  try {
    var payload = {
      id: productid,
      name: productname,
      description: productdescription,
      price: productprice,
    };
    return await PATCH<Product>(`/product/admin/`, payload);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function createProduct(
  productname: string,
  productdescription: string,
  productprice: number
): Promise<Product | undefined> {
  try {
    var payload = {
      name: productname,
      description: productdescription,
      price: productprice,
    };
    console.log(payload);
    return await POST<Product>(`/product/`, payload, {});
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

// get all store products
export async function getAllStoreProducts(
  limit: number = 50,
  full: boolean = true
): Promise<StoreProduct[] | undefined> {
  try {
    return await GET<StoreProduct[]>(`/store/products`, {
      limit: limit,
      full: full,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

// get one specific store product
export async function getStoreProduct(
  productId: string,
  full: boolean = true
): Promise<StoreProduct | undefined> {
  try {
    return await GET<StoreProduct>(`/store/products/${productId}`, {
      full: full,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function purchaseProduct(productId: string): Promise<boolean> {
  try {
    await POST<StoreProduct>(`/store/purchase`, {
      product_id: productId,
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function purchaseMultipleProducts(
  productIds: string[]
): Promise<boolean> {
  try {
    await POST<StoreProduct>(`/store/purchase/multiple`, {
      product_id: productIds,
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}
