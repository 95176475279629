import { POST, GET, PATCH, DELETE } from "@/rest/client";

import type { Payment, DonationLink } from "@/@types/payment";
import type { Transaction } from "@/@types/transaction";

/* Deprecated events using PaymentLinks

export async function getStripePaymentLink(price: number): Promise<string> {
  try {
    return (
      await POST<{
        url: string;
      }>(
        `/payments/stripe/create`,
        {
          amount: price,
        },
        {}
      )
    ).url;
  } catch (e) {
    console.error(e);
    return "";
  }
}

export async function verifyStripePaymentLink(
  token: string
): Promise<number | undefined> {
  try {
    return (
      await POST<{
        balance: number;
      }>(
        `/payments/stripe/verify`,
        {
          token: token,
        },
        {}
      )
    ).balance;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function cancelStripePaymentLink(url: string) {
  try {
    await POST(
      `/payments/stripe/cancel`,
      {
        url: url,
      },
      {}
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
} */

export async function getPendingPayments(): Promise<Payment[] | undefined> {
  try {
    return await GET<Payment[]>("/payments/pending", {});
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getTransactions(): Promise<Transaction[] | undefined> {
  try {
    return await GET<Transaction[]>("/payments/transactions", {
      amount: 10,
      timestamp: true,
      full: true,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

// get transactions by year number
export async function getTransactionsByYear(year: number): Promise<Transaction[] | undefined> {
  try {
    return await GET<Transaction[]>(`/payments/transactions/year`, {
      year: year,
      timestamp: true,
      full: true,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getStripeCheckoutSessionLink(
  price: number
): Promise<string> {
  try {
    return (
      await POST<{
        url: string;
      }>(
        `/payments/stripe/session`,
        {
          amount: price,
        },
        {}
      )
    ).url;
  } catch (e) {
    console.error(e);
    return "";
  }
}

export async function getStripeCheckoutSessionLinkCustom(): Promise<string> {
  try {
    return (
      await POST<{
        url: string;
      }>(`/payments/stripe/session`, {}, {})
    ).url;
  } catch (e) {
    console.error(e);
    return "";
  }
}

// get embedded checkout session client secret
export async function getStripeCheckoutSessionClientSecret(): Promise<string> {
  try {
    return (
      await POST<{
        client_secret: string;
      }>(
        `/payments/stripe/session/embedded`,{},{}
      )
    ).client_secret;
  } catch (e) {
    console.error(e);
    return "";
  }
}

// get status of embedded checkout session from GET /session/embedded/status with query params session_id and token return status string
export async function getStripeCheckoutSessionStatus(session_id: string, token: string): Promise<string> {
  try {
    return (
      await GET<{
        status: string;
      }>(
        `/payments/stripe/session/embedded/status`, {session_id: session_id, token: token}
      )
    ).status;
  } catch (e) {
    console.error(e);
    return "";
  }
}

// cancel Embedded Checkout Session POST with secret to /session/embedded/cancel
export async function cancelStripeCheckoutSessionEmbedded(secret: string): Promise<boolean> {
  try {
    await POST(`/payments/stripe/session/embedded/cancel`, {secret: secret}, {});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}


export async function verifyStripeCheckoutSession(
  token: string
): Promise<number | undefined> {
  try {
    return (
      await POST<{
        balance: number;
      }>(
        `/payments/stripe/session/verify`,
        {
          token: token,
        },
        {}
      )
    ).balance;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function cancelStripeCheckoutSession(url: string, token: string) {
  try {
    await POST(
      `/payments/stripe/session/cancel`,
      {
        url: url,
        token: token,
      },
      {}
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getDonationLink(
  userid: string
): Promise<DonationLink | undefined> {
  try {
    return await GET<DonationLink>(`/payments/stripe/donation/${userid}`, {});
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function createDonationLink(
  userid: string
): Promise<DonationLink | undefined> {
  try {
    return await POST<DonationLink>(
      `/payments/stripe/donation/${userid}`,
      {},
      {}
    );
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function changeDonationLink(
  userid: string,
  active: boolean
): Promise<DonationLink | undefined> {
  try {
    return await PATCH<DonationLink>(
      `/payments/stripe/donation/${userid}?active=${active}`,
      {},
      {}
    );
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getPublicDonationLink(
  reference: string
): Promise<string> {
  try {
    return (
      await GET<{ link: string }>(
        `/payments/stripe/donation/public/${reference}`,
        {}
      )
    ).link;
  } catch (e) {
    console.error(e);
    return "";
  }
}

export async function createBankTransferPayment(
  amount: number
): Promise<Payment | undefined> {
  try {
    return await POST<Payment>(
      `/payments/banktransfer/`,
      {
        amount: amount,
      },
      {}
    );
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function cancelBankTransferPayment(token: string) {
  try {
    await DELETE(`/payments/banktransfer/${token}`, {});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export interface BankDetails {
  name: string;
  iban: string;
  bic: string;
  bank: string;
}

export async function getBankDetails(): Promise<BankDetails | undefined> {
  try {
    return await GET<BankDetails>(`/payments/banktransfer/details`, {});
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
