import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";

import { colorsPreset, createVuestic } from "vuestic-ui";
import globalComponents from "@/components/components/globalComponents";
import "@fortawesome/fontawesome-free/css/all.css";
import "vuestic-ui/css";
import "@/styles/index.scss";

const app = createApp(App);

app.use(i18n);

app.use(
  createVuestic({
    config: {
      colors: {
        variables: {
          primary: "#E9376C",
          secondary: "#818992",
          success: "#28A745",
          info: "#6262C3",
          danger: "#f01400",
          warning: "#FFD952",
          backgroundPrimary: "#0E0C14",
          backgroundSecondary: "#1F262F",
          backgroundElement: "#131A22",
          backgroundBorder: "#3D4C58",
          backgroundGradient1: "#07060a",
          backgroundGradient2: "#26242c",
          backgroundGradient3: "#87868a",
          textPrimary: "#F1F1F1",
          textInverted: "#0B121A",
          shadow: "rgba(255, 255, 255, 0.12)",
          focus: "#6262C3",
          backgroundLanding: "#070d14",
          backgroundLandingBorder: "rgba(43, 49, 56, 0.8)",
        },
      },
    },
  })
);
app.use(globalComponents);
app.use(createPinia());

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  environment: import.meta.env.VITE_ENV as string,
  integrations: [
    Sentry.browserTracingIntegration({
      router,
    }),
    Sentry.captureConsoleIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      autoInject: true,
      showBranding: false,
      colorScheme: "dark",
      themeDark: {
        submitBackground: "#E9376C",
        submitBorder: "#E9376C",
        submitBackgroundHover: "#C83260",
      }
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
  // @ts-expect-error / const APP_VERSION available globally
  release: "v" + APP_VERSION,
});

app.use(router);

app.mount("#app");
