<script setup lang="ts">
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { RouterView } from 'vue-router';

import { useGeneralStore } from './stores/general';
const store = useGeneralStore();

import { wait } from '@/utils/wait';

import i18n from '@/i18n';

import Maintenance from "@/components/Maintenance.vue";
import Loading from "@/components/Loading.vue";

import { updateMyLocale } from '@/rest/api/users';

const isMaintenance = ref(0);

const backgroundType = localStorage.getItem('backgroundType');

// Function to check API health and update UI accordingly
const checkApiHealth = async () => {
  try {
    const response = await axios.get(import.meta.env.VITE_API_URL + '/health');
    if (response.status === 200) {
      isMaintenance.value = 2;
    } else {
      isMaintenance.value = 1;
    }
  } catch (error) {
    console.error('API check failed:', error);
    isMaintenance.value = 1;
  }
};

// Set background based on saved setting
const setBackground = () => {
  if (backgroundType) {
    if (backgroundType === 'Image') {
      document.body.classList.add('body-image');
      document.body.classList.remove('body-gradient');
    } else if (backgroundType === 'Gradient') {
      document.body.classList.add('body-gradient');
      document.body.classList.remove('body-image');
    } else {
      document.body.classList.remove('body-gradient');
      document.body.classList.remove('body-image');
    }
  }
};

const setLocale = async () => {
  const locale = localStorage.getItem('locale');
  if (locale) {
    let tries = 0;
    while (!store.isLoggedIn) {
      if (tries > 100) {
        console.log('User not logged in, not updating locale in backend');
        return;
      }
      tries++;
      await wait(5000)
    }

    var i18nlocale = ref<"en" | "de">("en");

    if (locale === 'en' || locale === 'de') {
      i18nlocale.value = locale;
    } else {
      i18nlocale.value = 'en';
    }
    updateMyLocale(i18nlocale.value);
    i18n.global.locale.value = i18nlocale.value;
  }
};

onMounted(() => {
  setBackground();
  setLocale();
  checkApiHealth();
  setInterval(checkApiHealth, 60000); // Recheck every 60000 milliseconds (1 minute)
});

</script>

<template>
  <Maintenance v-if="isMaintenance == 1" />
  <RouterView v-else-if="isMaintenance == 2" />
  <Loading v-else />
</template>

<style>
/* change webkit scrollbar */
::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}
</style>
