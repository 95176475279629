// src/global-components.ts
import type { App } from 'vue';
import Card from '@/components/components/card/card.vue';
import CardTitle from '@/components/components/card/cardTitle.vue';
import CardContent from '@/components/components/card/cardContent.vue';

export default {
  install(app: App) {
    app.component('Card', Card);
    app.component('CardTitle', CardTitle);
    app.component('CardContent', CardContent);
  },
};
