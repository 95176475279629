import type { Gameserver, GameserverImage } from "@/@types/gameserver";
import { GET, POST } from "@/rest/client";

export async function getGameserver(
  gameserverId: string,
  full: boolean = false,
  includeDate: boolean = false
): Promise<Gameserver | undefined> {
  try {
    return await GET<Gameserver>(`/gameserver/${gameserverId}`, {
      full: full,
      includeDate: includeDate,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getGameservers(
  full: boolean = false,
  includeDate: boolean = false
): Promise<Gameserver[] | undefined> {
  try {
    return await GET<Gameserver[]>(`/gameserver/`, {
      full: full,
      includeDate: includeDate,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getGameserverStatus(
  gameserverId: string
): Promise<boolean> {
  try {
    return (
      await GET<{ online: boolean }>(`/gameserver/${gameserverId}/ping`, {})
    ).online;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getGameserverLogs(gameserverId: string): Promise<string> {
  try {
    return (await GET<{ logs: string }>(`/gameserver/${gameserverId}/logs`, {}))
      .logs;
  } catch (e) {
    console.error(e);
    return "";
  }
}

// post /gameserver/:id/start
export async function startGameserver(gameserverId: string): Promise<boolean> {
  try {
    await GET(`/gameserver/${gameserverId}/start`, {});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// post /gameserver/:id/stop
export async function stopGameserver(gameserverId: string): Promise<boolean> {
  try {
    await GET(`/gameserver/${gameserverId}/stop`, {});
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// post send command to gameserver
export async function sendCommand(
  gameserverId: string,
  command: string
): Promise<boolean> {
  try {
    await POST<boolean>(
      `/gameserver/${gameserverId}/command`,
      {
        command: command,
      },
      {}
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// post send command to gameserver
export async function setNickname(
  gameserverId: string,
  nickname: string
): Promise<Gameserver | undefined> {
  try {
    return await POST<Gameserver | undefined>(
      `/gameserver/${gameserverId}/nickname`,
      {
        nickname: nickname,
      },
      {}
    );
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getImagesWithCategory(
  category: string,
): Promise<GameserverImage[] | undefined> {
  try {
    return await GET<GameserverImage[]>(`/gameserver/images`, {
      category: category,
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getImages(): Promise<GameserverImage[] | undefined> {
  try {
    return await GET<GameserverImage[]>(`/gameserver/images`, {});
  } catch (e) {
    console.error(e);
    return undefined;
  }
}


// post reinstall gameserver
export async function reinstallGameserver(
  gameserverId: string,
  imageId: string
): Promise<boolean> {
  try {
    await POST<boolean>(
      `/gameserver/${gameserverId}/recreate`,
      {
        imageId: imageId,
      },
      {}
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}